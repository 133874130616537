var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "form-element-number-input-demo" } },
    [
      _c("number-input-default"),
      _c("number-input-color"),
      _c("number-input-min-max"),
      _c("number-input-size"),
      _c("number-input-step"),
      _c("number-input-decimal"),
      _c("number-input-label"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }