var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v("You can change the color of the "),
        _c("strong", [_vm._v("Input-Number")]),
        _vm._v(" with the property "),
        _c("code", [_vm._v("color")]),
        _vm._v(". You are able to use the Main Colors or "),
        _c("strong", [_vm._v("RGB")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("HEX")]),
        _vm._v(" colors."),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input-number", {
            attrs: { color: "danger" },
            model: {
              value: _vm.number1,
              callback: function ($$v) {
                _vm.number1 = $$v
              },
              expression: "number1",
            },
          }),
          _c("vs-input-number", {
            attrs: { color: "success" },
            model: {
              value: _vm.number2,
              callback: function ($$v) {
                _vm.number2 = $$v
              },
              expression: "number2",
            },
          }),
          _c("vs-input-number", {
            attrs: { color: "warning" },
            model: {
              value: _vm.number3,
              callback: function ($$v) {
                _vm.number3 = $$v
              },
              expression: "number3",
            },
          }),
          _c("vs-input-number", {
            attrs: { color: "dark" },
            model: {
              value: _vm.number4,
              callback: function ($$v) {
                _vm.number4 = $$v
              },
              expression: "number4",
            },
          }),
          _c("vs-input-number", {
            attrs: { color: "rgb(218, 24, 94)" },
            model: {
              value: _vm.number5,
              callback: function ($$v) {
                _vm.number5 = $$v
              },
              expression: "number5",
            },
          }),
          _c("vs-input-number", {
            attrs: { color: "#ad3af9" },
            model: {
              value: _vm.number6,
              callback: function ($$v) {
                _vm.number6 = $$v
              },
              expression: "number6",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="centerx">\n    <vs-input-number color="danger" v-model="number1"/>\n    <vs-input-number color="success" v-model="number2"/>\n    <vs-input-number color="warning" v-model="number3"/>\n    <vs-input-number color="dark" v-model="number4"/>\n    <vs-input-number color="rgb(218, 24, 94)" v-model="number5"/>\n    <vs-input-number color="#ad3af9" v-model="number6"/>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      number1:196,\n      number2:88,\n      number3:25,\n      number4:12,\n      number5:590,\n      number6:32,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }