var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Step", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "To change the number to be added or decreased of the component, you can use the property "
        ),
        _c("code", [_vm._v("step")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input-number", {
            attrs: { step: 5 },
            model: {
              value: _vm.number0,
              callback: function ($$v) {
                _vm.number0 = $$v
              },
              expression: "number0",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="centerx">\n    <vs-input-number v-model="number0" :step="5"/>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      number0:5,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }