var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "If you need to add a component that handles a single number, use the component "
        ),
        _c("code", [_vm._v("vs-input-number")]),
      ]),
      _c("p", [
        _vm._v(
          "You can also change the increment and decrement button icons using the properties "
        ),
        _c("code", [_vm._v("icon-inc")]),
        _vm._v(" and "),
        _c("code", [_vm._v("icon-dec")]),
        _vm._v(" respectively"),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input-number", {
            model: {
              value: _vm.number,
              callback: function ($$v) {
                _vm.number = $$v
              },
              expression: "number",
            },
          }),
          _c("vs-input-number", {
            attrs: { disabled: true },
            model: {
              value: _vm.number1,
              callback: function ($$v) {
                _vm.number1 = $$v
              },
              expression: "number1",
            },
          }),
          _c("vs-input-number", {
            attrs: { "icon-inc": "expand_less", "icon-dec": "expand_more" },
            model: {
              value: _vm.number2,
              callback: function ($$v) {
                _vm.number2 = $$v
              },
              expression: "number2",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="centerx">\n    <vs-input-number v-model="number"/>\n    <vs-input-number :disabled="true" v-model="number1"/>\n    <vs-input-number v-model="number2" icon-inc="expand_less" icon-dec="expand_more"/>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      number:34,\n      number1:48,\n      number2:50\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }