var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Size", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "To change the size of the component, you can use the property "
        ),
        _c("code", [_vm._v("size")]),
        _vm._v(". The allowed values are: "),
        _c("code", [_vm._v("medium")]),
        _vm._v(", "),
        _c("code", [_vm._v("small")]),
        _vm._v(", and "),
        _c("code", [_vm._v("mini")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input-number", {
            model: {
              value: _vm.number0,
              callback: function ($$v) {
                _vm.number0 = $$v
              },
              expression: "number0",
            },
          }),
          _c("vs-input-number", {
            attrs: { size: "medium" },
            model: {
              value: _vm.number1,
              callback: function ($$v) {
                _vm.number1 = $$v
              },
              expression: "number1",
            },
          }),
          _c("vs-input-number", {
            attrs: { size: "small" },
            model: {
              value: _vm.number2,
              callback: function ($$v) {
                _vm.number2 = $$v
              },
              expression: "number2",
            },
          }),
          _c("vs-input-number", {
            attrs: { size: "mini" },
            model: {
              value: _vm.number3,
              callback: function ($$v) {
                _vm.number3 = $$v
              },
              expression: "number3",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="centerx">\n    <vs-input-number v-model="number0"/>\n    <vs-input-number size="medium" v-model="number1"/>\n    <vs-input-number size="small" v-model="number2"/>\n    <vs-input-number size="mini" v-model="number3"/>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      number0:95,\n      number1:21,\n      number2:67,\n      number3:2\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }