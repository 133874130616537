var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Min - Max", "code-toggler": "" } },
    [
      _c("span", [
        _vm._v(
          "You can restrict the maximum number or the minimum with the properties "
        ),
        _c("code", [_vm._v("min")]),
        _vm._v(", "),
        _c("code", [_vm._v("max")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-input-number", {
            attrs: { min: "13", max: "21" },
            model: {
              value: _vm.number1,
              callback: function ($$v) {
                _vm.number1 = $$v
              },
              expression: "number1",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="centerx">\n    <vs-input-number min="13" max="21" v-model="number1"/>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      number1:21,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }